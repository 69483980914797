<template>
  <b-row class="match-height">
    <b-col>
      <b-card-actions action-collapse :title="'Daftar Akun'">
        <b-row>
          <b-col md="6" sm="6">
            <b-button v-b-modal.modal-tambah variant="primary" @click="add()" v-if="allowCreate()">
              <feather-icon icon="PlusIcon" class="mr-50" />
              Akun Baru
            </b-button>
          <!-- </b-col>
          <b-col md="4" sm="4"> -->
            <b-button v-b-modal.modal-tambah variant="primary" class="ml-1" v-if="allowCreate()"
              @click.prevent="$router.push(`/jurnal`)">
              <!-- @click="addKategori()" -->
              <feather-icon icon="PlusIcon" class="mr-50" />
              Buat Jurnal Umum
            </b-button>
          </b-col>
          <b-col md="4" sm="4" v-if="this.user.member_id = null">
            <b-button v-b-modal.modal-setbiaya variant="primary" v-if="allowCreate()" @click="showsetbiaya()">
              <!-- @click.prevent="$router.push(`/jurnal`)" -->
              <feather-icon icon="PlusIcon" class="mr-50" />
              Setting Biaya
            </b-button>
          </b-col>
        </b-row>
        <b-modal v-model="showModal" id="modal-akun" size="lg" ok-title="Tutup" ok-variant="secondary" ok-only centered
          title="Form Pembuatan Akun" no-close-on-backdrop no-close-on-esc>
          <validation-observer ref="formakun">
            <b-form>
              <b-row>
                <b-col cols="12" class="mb-2">
                  <b-form-group label="Pilih Kategori">
                    <!-- label-for="v-id_kategori" -->
                    <validation-provider #default="{ errors }" rules="required" name="id_kateori">
                      <b-form-select v-model="form.id_kategori" :options="id_kategori" id="v-id_kategori"
                        name="id_kategori" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12" lg="6" class="mb-2">
                  <b-form-group label="Pilih Jenis Akun" label-for="v-jenis">
                    <validation-provider #default="{ errors }" name="jenis" rules="">
                      <div>
                        <b-form-radio-group v-model="form.jenis" :options="options" class="inline-spacing"
                          value-field="value" text-field="text" disabled-field="disabled" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- neraca -->
                <b-col sm="12" lg="6">
                  <b-form-group label="Pilih Neraca" label-for="v-jenis_neraca">
                    <validation-provider #default="{ errors }" name="jenis_neraca" rules="">
                      <div>
                        <b-form-radio-group v-model="form.jenis_neraca" :options="options_neraca" class="inline-spacing"
                          value-field="value" text-field="text" disabled-field="disabled" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Nomor Akun -->
                <b-col cols="12" class="mb-2">
                  <b-form-group label="Nomor Akun" label-for="v-nomor">
                    <validation-provider #default="{ errors }" rules="required" name="akun">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.nomor" id="v-nomor"
                        placeholder="Isi nomor akun" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Nama -->
                <b-col cols="12" class="mb-2">
                  <b-form-group label="Nama Akun" label-for="v-nama">
                    <validation-provider #default="{ errors }" rules="required" name="akun">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.nama" id="v-nama"
                        placeholder="Isi nama akun" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- pajak -->
                <b-col cols="12" class="mb-2">
                  <b-form-group label="Pajak Akun %" label-for="v-pajak">
                    <validation-provider #default="{ errors }" rules="required" name="akun">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.pajak" id="v-pajak"
                        placeholder="Isi pajak akun" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Opsi dimasukan hutang atau tidak -->
                <b-col sm="12" class="mb-2">
                  <b-form-group label="Masukan Akun Sebagai Hutang?" label-for="v-hutang">
                    <validation-provider #default="{ errors }" name="hutang" rules="required">
                      <div>
                        <b-form-radio-group v-model="form.hutang"
                          :options="[{ value: 1, text: 'Ya' }, { value: 0, text: 'Tidak' }]" class="inline-spacing"
                          value-field="value" text-field="text" disabled-field="disabled" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Opsi tampilkan akun ini di laporan neraca -->
                <b-col sm="12" md="6" class="mb-2">
                  <b-form-group label="Tampilkan Akun di Neraca?" label-for="v-neraca">
                    <validation-provider #default="{ errors }" name="neraca" rules="required">
                      <div>
                        <b-form-radio-group v-model="form.neraca"
                          :options="[{ value: 1, text: 'Ya' }, { value: 0, text: 'Tidak' }]" class="inline-spacing"
                          value-field="value" text-field="text" disabled-field="disabled" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Opsi tampilkan akun ini di laporan laba rugi -->
                <b-col sm="12" md="6" class="mb-2">
                  <b-form-group label="Tampilkan Akun di Laba Rugi?" label-for="v-laba_rugi">
                    <validation-provider #default="{ errors }" name="laba_rugi" rules="required">
                      <div>
                        <b-form-radio-group v-model="form.laba_rugi"
                          :options="[{ value: 1, text: 'Ya' }, { value: 0, text: 'Tidak' }]" class="inline-spacing"
                          value-field="value" text-field="text" disabled-field="disabled" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>

          </validation-observer>

          <template #modal-footer>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="submit" variant="primary"
              class="mr-1">
              Simpan
            </b-button>
          </template>
        </b-modal>
        <b-modal v-model="moodalBiaya" id="modal-moodalBiaya" size="sm" ok-title="Tutup" ok-variant="secondary" ok-only
          centered :title="'Edit Biaya' + formbiaya.name" no-close-on-backdrop no-close-on-esc>
          <b-row>
            <b-col cols="12" class="mb-2">
              <b-form-group label="Nominal" >
                  <b-form-input v-model="formbiaya.nominal" 
                    placeholder="Isi nominal" />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mb-2">
              <b-form-group label="Pilih Akun">
                  <b-form-select v-model="formbiaya.akun_id" :options="akuns" />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mb-2">
              <b-form-group label="Pilih Akun">
                  <b-form-select v-model="formbiaya.kas_id" :options="kass" >
                    
      <template #first>
        <b-form-select-option :value="null" >-- Kosongkan --</b-form-select-option>
      </template>
      </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <template #modal-footer>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="submitbiaya" variant="primary"
              class="mr-1">
              Simpan
            </b-button>
          </template>
        </b-modal>
        <b-modal v-model="setbiaya" id="modal-setbiaya" size="lg" ok-title="Tutup" ok-variant="secondary" ok-only
          centered title="Setting Biaya" no-close-on-backdrop no-close-on-esc>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select id="perPageSelectBiaya" v-model="perPageBiaya" size="sm" :options="pageOptionsBiaya"
                  class="w-50" />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="sortBySelectBiaya" class="mb-0">
                <b-input-group size="sm">
                  <b-form-select id="sortBySelectBiaya" v-model="sortByBiaya" :options="sortOptionsBiaya" class="w-75">
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDescBiaya" size="sm" :disabled="!sortByBiaya" class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group label="FilterBiaya" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="filterInputBiaya" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filterInputBiaya" v-model="filterBiaya" type="search"
                    placeholder="Type to Search" />
                  <b-input-group-append>
                    <b-button :disabled="!filterBiaya" @click="filterBiaya = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table striped small hover responsive :per-page="perPageBiaya" :current-page="currentPageBiaya"
                :items="biaya" :fields="fieldsBiaya" :sort-by.sync="sortByBiaya" :sort-desc.sync="sortDescBiaya"
                :sort-direction="sortDirectionBiaya" :filter="filterBiaya" :filter-included-fields="filterOnBiaya"
                @filtered="onFilteredBiaya">
                <template #cell(no)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(nominal)="{ item }">
                  {{ formatRupiah(item.nominal) }}
                </template>
                <template #cell(jenis)="data">
                  <b-badge :variant="ket_jenis[1][data.value]">
                    {{ ket_jenis[0][data.value] }}
                  </b-badge>
                </template>
                <template #cell(hutang)="{ item }">
                  <b-badge variant="light-info">
                    {{ item.hutang ? 'Ya' : 'Tidak' }}
                  </b-badge>
                </template>
                <template #cell(saldo)="{ item }">
                  <strong>
                    {{
        item.saldo >= 0 ? formatRupiah(item.saldo) : "( " + formatRupiah(item.saldo * -1) + " )"
      }}</strong>
                </template>

                <template #cell(actions)="row">
                  <b-button v-b-tooltip.hover.right="'Ubah'" size="sm" @click="editbiaya(row.item)" class="mr-1"
                    variant="outline-info">
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </b-card>
                </template>
              </b-table>
            </b-col>

            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
                class="my-0" />
            </b-col>
          </b-row>

          <template #modal-footer>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="submit" variant="primary"
              class="mr-1">
              Simpan
            </b-button>
          </template>
        </b-modal>
        <b-row>
          <!-- <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="8" class="my-1">
            <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col> -->
          <b-col md="6" class="my-1">
            <b-form-group label="Filter"
              label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input v-model="filter" placeholder="Tekan enter untuk cari" size="sm"
                      @keydown.enter.prevent="getData()"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click="getData()" size="sm">
                        Cari
                      </b-button>
                    </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table striped small hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
              :fields="fields" >
              <template #cell(no)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(pajak)="{ item }">
                {{ item.pajak }}%
              </template>
              <template #cell(jenis)="data">
                <b-badge :variant="ket_jenis[1][data.value]">
                  {{ ket_jenis[0][data.value] }}
                </b-badge>
              </template>
              <template #cell(hutang)="{ item }">
                <b-badge variant="light-info">
                  {{ item.hutang ? 'Ya' : 'Tidak' }}
                </b-badge>
              </template>
              <template #cell(saldo)="{ item }">
                <strong>
                  {{
        item.saldo >= 0 ? formatRupiah(item.saldo) : "( " + formatRupiah(item.saldo * -1) + " )"
      }}</strong>
              </template>
              <template #cell(nama)="{ item }">
                <span @click.prevent="$router.push(`/akun/${item.id}`)"><u>{{ item.nama }}</u></span>
              </template>
              <template #cell(nomor)="{ item }">
                <span @click.prevent="$router.push(`/akun/${item.id}`)"><u>{{ item.nomor }}</u></span>
              </template>

              <template #cell(actions)="row">
                <b-button title="Detail" size="sm" @click="$router.push(`/akun/info/${row.item.id}`)" class="mr-1"
                  variant="outline-info">
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button title="Ubah" v-if="allowUpdate() && isFinance && row.item.show_to_others == 1" size="sm" @click="edit(row.item)"
                  class="mr-1" variant="outline-info">
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button v-if="row.item.is_deleteable = 0" title="Hapus" size="sm" @click="remove(row.item)" class="mr-1"
                  variant="outline-danger">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">
                      {{ key }}: {{ value }}
                    </li>
                  </ul>
                </b-card>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
              class="my-0" />
          </b-col>
        </b-row>
      </b-card-actions>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BFormSelectOption,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormRadio,
  BFormRadioGroup,
  BButton,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
  BFormSelectOption,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    BFormRadioGroup,
    VBTooltip,
    BFormTimepicker,
    BForm,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormRadio,
    BCardText,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModal: false,
      formbiaya: {
        code: null,
        nominal: null,
        kas_id: null,
        akun_id: null,
      },
      akuns: null,
      kass: null,
      idbiaya: null,
      moodalBiaya: false,
      setbiaya: false,
      biaya: null,
      loading: false,
      perPageBiaya: 20,
      pageOptionsBiaya: [20, 50, 100],
      totalRowsBiaya: 1,
      currentPageBiaya: 1,
      sortByBiaya: "",
      sortDescBiaya: false,
      sortDirectionBiaya: "asc",
      filterBiaya: null,
      filterOnBiaya: [],
      fieldsBiaya: [
        { key: "no", label: "no" },
        { key: "code", label: "Code", sortable: true },
        { key: "name", label: "Nama Biaya", sortable: true },
        { key: "nominal", label: "Nominal (Rp)", sortable: true },
        { key: "akun.nama", label: "Akun", sortable: true },
        { key: "kas.nama", label: "Kas", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      required,
      password,
      email,
      confirmed,
      form: {
        nomor: null,
        nama: null,
        pajak: null,
        hutang: 0,
        jenis: 0,
        jenis_neraca: 0,
        // saldo: null,
        id_kategori: null,
      },
      options: [
        { value: 1, text: "Debit" },
        { value: 2, text: "Kredit" },
      ],
      options_neraca: [],
      ket_jenis: [
        {
          1: "Debit",
          2: "Kredit",
          3: "Debit & Kredit",
        },
        {
          1: "light-success",
          2: "light-danger",
          3: "light-primary",
        },
      ],
      id_kategori: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 100000,
      pageOptions: [20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "no", label: "no" },
        { key: "nomor", label: "Kode Akun", sortable: true },
        { key: "nama", label: "Nama Akun", sortable: true },
        { key: "jenis", label: "jenis", sortable: true },
        { key: "saldo", label: "Nominal (Rp)", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
      jns: [
        {
          1: "Masuk",
          2: "Keluar",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptionsBiaya() {
      // Create an options list from our fields
      return this.fieldsBiaya
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.getData();
    this.getkategori();
    this.getneraca();
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      // const userData = JSON.parse(localStorage.getItem("userData"));
      // if (userData.id) {
      //   this.form.id_kategori = userData.karyawan.id;
      //   this.form.id_kas = this.$route.params.id;
      // }
    },
    editbiaya(itembiaya) {
      this.formbiaya = itembiaya;
      this.idbiaya = itembiaya.id;
      this.moodalBiaya = true;
    },
    showsetbiaya() {
      this.setbiaya = true;
      this.getDataBiaya()
      this.getkas()
    },
    async getDataBiaya() {
      this.loading = true
      await this.$store
        .dispatch("setbiaya/getData", {})
        .then(() => {
          let hem = JSON.parse(
            JSON.stringify(this.$store.state.setbiaya.datas)
          );
          this.biaya = hem
          this.loading = false
          // hem.map((item) => {
          //   item.value = item.id;
          //   item.text = item.kategori;
          // });
          // this.id_kategori = hem;
        })
        .catch((e) => {
          this.loading = false
          this.displayError(e);
          return false;
        });

    },
    submitbiaya() {
          this.label = "Loading...";
          if (this.id) {
            this.formbiaya.id = this.idbiaya;
          }
          const payload = this.formbiaya;
          this.$store
            .dispatch("setbiaya/save", [payload])
            .then(() => {
              this.label = "Submit";
              this.resetForm();
              this.displaySuccess({
                message: "Data Setting Biaya berhasil disimpan"
              });
              this.showModal = false;
              this.getDataBiaya();
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Akun "${item.nama}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("akun/save", [item])
            .then(() => {
              this.getData();
              // this.pesanBerhasilHapus();
              this.displaySuccess({
                message: "Data Akun berhasil terhapus"
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        nomor: null,
        nama: null,
        pajak: null,
        // saldo: null,
      };
    },
    submit() {
      this.$refs.formakun.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form.id = this.id;
          }
          if (this.userData.member_id != null){
            this.form.member_id = this.userData.member_id
          }
          const payload = this.form;
          this.$store
            .dispatch("akun/save", [payload])
            .then(() => {
              this.label = "Submit";
              this.resetForm();
              this.displaySuccess({
                message: "Data Akun berhasil disimpan"
              });
              this.showModal = false;
              this.getData();
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async getData() {
      //SEMUA kecuali SALES
      let payload = {
        search: this.filter != null ? this.filter : null,
        order: "asc",
        // start: currentPage,
        // length: this.perPage,
      };
      if (this.userData.member_id == null){ 
        payload.show_to_others = this.kategoripusat ? this.kategoripusat : null
      } else {
        payload.member_id = this.userData.member_id ? this.userData.member_id : null
      }
      const akuns = await this.$store.dispatch("akun/getData", payload)
      const debitAkun = akuns.filter(akun => akun.jenis == 1)
      const kreditAkun = akuns.filter(akun => akun.jenis == 2)
      const dekreAkun = akuns.filter(akun => akun.jenis == 3)
      this.items = [...debitAkun, ...kreditAkun, ...dekreAkun]
      const hem = [...debitAkun, ...kreditAkun, ...dekreAkun]
          hem.map((item) => {
            item.value = item.id;
            item.text = item.jenis == 2 ? item.nama + ' - ' + 'Kredit' : item.nama + ' - ' + 'Debit'  ;
          });
          this.akuns = hem
      

      // this.items = this.$store.state.akun.datas;
      this.totalRows = this.items.length;
      // if (this.userData.level.id == 7) {
      //   //SALES
      //   this.$store.dispatch("akun/getData", {}).then(() => {
      //     this.items = this.$store.state.akun.datas;
      //     this.totalRows = this.items.length;
      //   });
      // }
    },
    getkategori() {
      this.$store
        .dispatch("akun_kategori/getData", {})
        .then(() => {
          let hem = JSON.parse(
            JSON.stringify(this.$store.state.akun_kategori.datas)
          );
          hem.map((item) => {
            item.value = item.id;
            item.text = item.kategori;
          });
          this.id_kategori = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getkas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let hem = JSON.parse(
            JSON.stringify(this.$store.state.kas.datas)
          );
          hem.map((item) => {
            item.value = item.id;
            item.text = item.nama;
          });
          this.kass = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getneraca() {
      this.$store
        .dispatch("neraca/getData", {})
        .then(() => {
          let hem = JSON.parse(
            JSON.stringify(this.$store.state.neraca.datas)
          );
          hem.map((item) => {
            item.value = item.id;
            item.text = item.neraca;
          });
          this.options_neraca = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredBiaya(filteredItemsBiaya) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsBiaya = filteredItemsBiaya.length;
      this.currentPageBiaya = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
